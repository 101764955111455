$color-text: #9e9e9e;
$color-line: #9e9e9e;
$color-bg: #fff;

.tree {
  svg {
    margin-right: 0.5rem;
    cursor: pointer;
  }
}

.tree,
.tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tree ul {
  margin-left: 0.5rem;
  position: relative;
}
.tree ul ul {
  margin-left: 0.5rem;
}
.tree ul:before {
  content: '';
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid;
  border-color: $color-line;
}
.tree li {
  margin: 0;
  padding: 0.3rem 1rem;
  line-height: 2rem;
  color: $color-text;
  position: relative;

  &:hover {
    color: #333;

    & > svg {
      color: var(--bs-secondary);
    }
  }
}
.tree ul li:before {
  content: '';
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: 1.3rem;
  left: 0;
  border-color: $color-line;
}
.tree ul li:last-child:before {
  background: $color-bg;
  height: auto;
  bottom: 0;
}
.indicator {
  margin-right: 5px;
}
.tree li a {
  text-decoration: none;
  color: $color-text;
  &:hover {
    color: #333;
  }
}
.tree li button,
.tree li button:active,
.tree li button:focus {
  text-decoration: none;
  color: $color-text;
  border: none;
  background: transparent;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  outline: 0;
}

.title {
  p {
    color: #c9c9c9;
  }
}
