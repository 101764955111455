@import '../../shared';

.card {
  @include box-parent;
  margin-bottom: 0.75rem;
  max-height: 100%;
  overflow-y: auto;
}

.tabContent {
  @include box-parent;

  flex-direction: column;

  & > :global(.tab-pane.active) {
    @include box-parent;

    & > :global(.card-body) {
      @include box-parent;
    }
  }
}

.tabContainer {
  margin-bottom: 0.5rem;
  & > :global(.nav-item) {
    display: flex;
    align-items: center;

    &::after {
      content: '----';
      opacity: 0.3;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &:first-child {
      :global(.nav-link) {
        padding-left: 0;
      }
    }
  }
}

.badge {
  border-radius: 0.5rem;
  padding: 0.65rem 1rem;
  font-size: 1.3rem;
  margin-right: 0.5rem;
  font-family: 'Rubik', sans-serif;

  &:global(.bg-light) {
    color: var(--bs-primary);
    background-color: #fff !important;
  }
}
