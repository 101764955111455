$gray-900: #333 !default;
$gray-100: #f1f1f1 !default;

$primary: #643df2 !default;
$secondary: #39ca8e !default;
$light: $gray-100 !default;
$dark: #082032 !default;

$light-gray: #9e9e9e !default;

$body-color: #333 !default;
$body-bg: #f1f1f1 !default;

$card-border-radius: 1rem;
$btn-border-radius: 1.25rem;
$btn-border-radius-sm: 1rem;
$btn-border-radius-lg: 2.25rem;

$dropdown-padding-y: 0;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1rem;

$input-border-color: $gray-100 !default;
$input-border-radius: 0.4rem !default;
$input-border-radius-sm: 0.325rem !default;
$input-border-radius-lg: 0.5rem !default;

$input-bg: #f1f1f1 !default;

$spacer: 2.25rem !default;

$card-border-color: $light !default;

$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-focus-width: none;

@mixin box-parent {
  flex: 1;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
}

@mixin box-child {
  display: flex;
  flex-direction: column;
}

@mixin box-child-centered {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
}
