.closeButton {
  color: red;
  stroke: white;
  stroke-width: 20;
  font-size: 20px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 50%
  );
}

.linkButton {
  height: 20px;
  border-color: white;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.nonSet {
  background-color: grey;
}
.set {
  background-color: var(--bs-secondary);
}

.banner {
  img {
    max-height: 300px;
  }
  svg {
    filter: drop-shadow(2px 3px 3px rgb(0 0 0 / 0.4));
    font-size: 25px;
  }
}

.divider {
  border: 0;
  border-top: 1px solid var(--bs-primary);
  margin: 10px auto;
  width: 120px;
}

.imageHeader {
  height: 27px;
  padding: 10px 10px 0px 10px;
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: 0;
  width: inherit;
}

.imageFooter {
  display: flex;
  padding-bottom: 3px;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: inherit;
}

.sortButton {
  svg {
    color: var(--bs-secondary);
    stroke: darkgreen;
    stroke-width: 10;

    font-size: 16px;

    &:first-of-type {
      padding-right: 5px;
    }

    &:last-of-type {
      padding-left: 5px;
    }

    &[disabled] {
      filter: grayscale(1);
    }
  }
}

.carouselContainer {
  height: 350px;
  font-style: italic;
  color: grey;
}

.plusButton {
  border-radius: 20px;
  background-color: var(--bs-primary);
  height: 100px;
  width: 100px;
  cursor: pointer;

  svg {
    color: #fff;
    font-size: 20px;
  }
}

.cameraPlaceholder {
  height: 100px;
  width: 100px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #c9c9c9;

  svg {
    color: #c9c9c9;
    font-size: 20px;
  }
}

.imageZone {
  display: flex;
  width: 100px;
  background-color: #f1f1f1;
  border-radius: 22px;

  & > img {
    border-radius: 20px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    position: relative;

    &[select] {
      border: 2px solid var(--bs-secondary);
    }
  }
}

.imageContainer {
  user-select: none;
  & > img {
    border-radius: 20px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    position: relative;

    &[select] {
      border: 2px solid var(--bs-secondary);
    }
  }
}
