.handlers {
  button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: none;
    background: transparent;

    svg {
      color: gray;
      font-size: 25px;
      width: 25px;
    }
  }
}
