.success {
  :global(.card) {
    text-align: center;
    width: fit-content;
    margin: auto;
    :global(.card-header) {
      background-color: var(--bs-white);
      border-bottom: none;
      h1 {
        margin-bottom: 0;
      }
    }
  }
}
