@import '../../shared';

.container {
  @include box-parent;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}
