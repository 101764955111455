.searchBar {
  height: 100%;

  .formControl {
    background-color: #fff;
    padding-left: 3rem;
    padding-right: 3rem;
    height: 100%;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;

    &:first-of-type {
      left: 1rem;
    }

    &:last-of-type {
      right: 1rem;
    }
  }

  .clearSearchBarIcon {
    cursor: pointer;
  }
}
