.checkout {
  display: grid;
  grid-template-columns: auto 500px;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;

  &.success {
    align-items: baseline;
  }

  .image {
    grid-row: 1 / span 2;
    width: auto;

    img {
      width: auto;
      height: 100%;
    }
  }

  .summary {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;

      :global(.card) {
        :global(.card-body) {
          padding: 1rem;
          text-align: center;

          h5 {
            margin-bottom: 0;
          }

          h2 {
            span {
              margin-left: 5px;
              font-size: 16px;
            }
          }
        }
      }
    }

    h1 {
      font-size: 4rem;
      margin-bottom: 0;
    }
  }

  .form {
    text-align: center;
    width: 100%;

    h1 {
      font-size: 4rem;
      margin-bottom: 0;
    }

    :global(.alert) {
      padding: 0.5rem;
    }

    form {
      margin-top: 1rem;

      div {
        width: 100%;
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0;

        button {
          width: fit-content;
          margin-top: 1rem;

          :global(.spinner-border) {
            width: 24px;
            height: 24px;
          }
        }
      }

      input,
      textarea {
        background-color: #fff;
        color: #000;
        text-align: center;

        &::placeholder {
          color: #000;
        }

        &:focus {
          background-color: #fff;
        }
      }
    }
  }

  .whatsapp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    
    svg {
      font-size: 4rem;
    }
  }

}

.cartReviewItem {
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border: 2px solid var(--bs-secondary);
  border-radius: 1rem;
}

@media (max-width: 991px) {
  .checkout {
    grid-template-columns: 35% 65%;
    justify-content: center;
    column-gap: 0.5rem;
    row-gap: 1rem;

    &.success {
      grid-template-columns: 100%;

      .image {
        img {
          width: 35%;
          display: block;
          margin: 0 auto;
        }
      }
    }

    .image {
      grid-row: auto;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }

    .summary {
      > div {
        flex-direction: column;
        width: 100%;
        :global(.card) {
          width: 100%;
          :global(.card-body) {
            padding: 0.75rem;

            h5 {
              margin-bottom: 0.5rem;
            }
          }
        }
      }

      h1 {
        font-size: 5vh;
      }
    }

    .form {
      grid-column: 1 / span 2;
    }
  }
}
