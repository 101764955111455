.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(white, 0.5);

  div {
    --bs-spinner-width: 3rem;
    --bs-spinner-height: 3rem;
    --bs-spinner-border-width: 0.375rem;
  }
}
