@import './shared';

@import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Rubik&display=swap');

@import 'react-bootstrap-typeahead/css/Typeahead.css';

:root {
  --bs-primary: var(--bl-primary, #{$primary});
  --bs-secondary: var(--bl-secondary, #{$secondary});
  --bs-primary-rgb: var(--bl-primary-rgb, #{to-rgb($primary)});
  --bs-secondary-rgb: var(--bl-secondary-rgb, #{to-rgb($secondary)});
  --bs-primary-hover: var(--bl-primary-hover, #5a37da);
  --bs-secondary-hover: var(--bl-secondary-hover, #39ca8e);
  --bs-primary-hover-rgb: var(--bl-primary-hover-rgb, #{to-rgb(#5a37da)});
  --bs-secondary-hover-rgb: var(--bl-secondary-hover-rgb, #{to-rgb(#39ca8e)});
  --bs-primary-contrast-color: var(--bl-primary-contrast-color, #{$white});
  --bs-secondary-contrast-color: var(--bl-secondary-contrast-color, #{$white});
  --bs-link-color: var(--bl-primary, #{$primary});
  --bs-link-hover-color: var(--bs-primary-hover);
}

* {
  font-family: 'Nunito', sans-serif;
}

html {
  font-size: 100%;
  background-color: var(--bs-primary-contrast-color);
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

code {
  font-family: monospace;
}

.card.bg-none {
  background-color: transparent;
  border-radius: 0;
  border-color: transparent;
}

.btn-primary {
  --bs-btn-color: var(--bs-primary-contrast-color);
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: var(--bs-primary-contrast-color);
  --bs-btn-hover-bg: var(--bs-primary-hover);
  --bs-btn-hover-border-color: var(--bs-primary-hover);
  --bs-btn-focus-shadow-rgb: var(--bs-primary-hover-rgb);
  --bs-btn-active-color: var(--bs-primary-contrast-color);
  --bs-btn-active-bg: var(--bs-primary-hover);
  --bs-btn-active-border-color: var(--bs-primary-hover);
  --bs-btn-disabled-color: var(--bs-primary-contrast-color);
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-secondary {
  --bs-btn-color: var(--bs-secondary-contrast-color);
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-color: var(--bs-secondary);
  --bs-btn-hover-bg: var(--bs-secondary-contrast-color);
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-focus-shadow-rgb: var(--bs-secondary-hover-rgb);
  --bs-btn-active-color: var(--bs-secondary-contrast-color);
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
  --bs-btn-disabled-color: var(--bs-secondary-contrast-color);
  --bs-btn-disabled-bg: var(--bs-secondary);
  --bs-btn-disabled-border-color: var(--bs-secondary);
}

.btn-primary-inverse {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-bg: var(--bs-primary-contrast-color);
  --bs-btn-border-color: var(--bs-primary-contrast-color);
  --bs-btn-hover-color: var(--bs-primary-contrast-color);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary-contrast-color);
  --bs-btn-focus-shadow-rgb: var(--bs-primary-hover-rgb);
  --bs-btn-active-color: var(--bs-primary-contrast-color);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary-contrast-color);
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: var(--bs-primary-contrast-color);
  --bs-btn-disabled-border-color: var(--bs-primary-contrast-color);
}

.alert {
  padding: 1rem;
}

.bg-primary {
  --bs-card-color: var(--bs-primary-contrast-color);
  color: var(--bs-primary-contrast-color);
}

.bg-secondary {
  --bs-card-color: var(--bs-secondary-contrast-color);
  color: var(--bs-secondary-contrast-color);
}

.dropdown-menu {
  --bs-dropdown-link-active-color: var(--bs-primary-contrast-color);
  --bs-dropdown-link-active-bg: var(--bs-primary);
}

input[type='number'] {
  /* Firefox */
  -moz-appearance: textfield;
  appearance: textfield;

  /* Chrome, Safari, etc. */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@include media-breakpoint-up(sm) {
  html {
    background-color: var(--bs-body-bg);
  }
}
