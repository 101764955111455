.card {
  min-height: 10rem;
  cursor: pointer;
  height: 100%;

  :global(.card-body) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-align: center;

    .seatedMan {
      position: relative;
      width: 145px;
      height: 180px;
      bottom: -0.5rem;
      margin-left: 0.5rem;

      img {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  :global(.card-text) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 600;

    svg {
      font-size: 3.5rem;
    }
  }
}
