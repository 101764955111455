.carousel {
  img {
    max-height: 500px;
    border-radius: 1rem;
  }

  :global(.carousel-control-next),
  :global(.carousel-control-prev) {
    width: 5%;
    opacity: 0.8;

    svg {
      color: var(--bs-secondary);
      font-size: 25px;
    }
  }
}

.newIcon {
  left: -20px;
  scale: 1;
  position: absolute;
  transform: rotate(340deg);
  z-index: 90;
  scale: 0.7;
}

.newIconTable {
  left: -20px;
  scale: 1;
  position: absolute;
  transform: rotate(340deg);
  z-index: 90;
  scale: 0.7;
}

.carouselIndicators {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  button {
    margin-right: 0.5rem;
    border: none;
    scroll-snap-align: start;
    white-space: nowrap;
    background: transparent;
    .carouselImageContainer {
      width: 80px;
      height: 80px;

      .carouselImageVideo {
        position: relative;
        display: flex;
        justify-content: center;
        top: -52px;
        .videoIcon {
          color: var(--bs-secondary);
        }
      }
    }

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border: solid lightgray 1px;
      border-radius: 5px;
      &.active {
        border: 2px solid var(--bs-secondary);
      }
    }
  }
}

.productTitle {
  padding-bottom: 1rem;
  border-bottom: 3px solid var(--bs-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    color: var(--bs-primary);
    margin-bottom: 0;

    span {
      font-size: 1rem;
      color: gray;
    }
  }

  button {
    svg {
      font-size: 20px;
      color: currentColor;
    }
  }
}

.productTitleMobile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  h1 {
    color: var(--bs-primary);
    margin-bottom: 0;
    width: 100%;

    span {
      font-size: 1rem;
      color: gray;
    }
  }
}

.productPrice {
  padding: 1rem 0;
  border-bottom: 1px solid #c9c9c9;
  display: flex;
  align-items: center;

  h5 {
    opacity: 0.7;
    text-decoration: line-through;
    margin-bottom: 0;
    margin-right: 10px;
  }

  h3 {
    margin-bottom: 0;

    span {
      margin-left: 5px;
      font-size: 16px;
    }
  }

  .priceList {
    margin-bottom: -5px;
    margin-left: 10px;
    opacity: 0.7;
  }
}

.productDescription {
  padding: 1rem 0;
  border-bottom: 1px solid #c9c9c9;
  margin-bottom: 1rem;
  h5 {
    color: var(--bs-primary);
  }

  p {
    margin-bottom: 0;

    button {
      color: var(--bs-primary);
      background: transparent;
      border: none;
      text-decoration: underline;
    }
  }
}

.popover {
  padding: 1rem;
  max-width: 40%;
  border: none;
  z-index: 100;
  box-shadow: 0px -1px 33px #00000012;
  border-radius: 17px;

  div {
    &:first-child {
      &::after,
      &::before {
        display: none;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h4 {
      color: var(--bs-primary);
      margin-bottom: 0;
    }

    button {
      background: transparent;
      border: none;
      svg {
        font-size: 25px;
      }
    }
  }

  .description {
    color: #707070;
    margin-bottom: 1rem;

    p {
      margin-bottom: 0;
      overflow-y: auto;
      max-height: 240px;
    }
  }

  .infoItems {
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        color: var(--bs-secondary);
        font-size: 18px;
      }

      p {
        color: #000;
        margin-bottom: 0;
        margin-left: 1rem;

        span {
          color: var(--bs-primary);
        }
      }
    }
  }

  .categories {
    background-color: var(--bs-gray-100);
    padding: 1rem;
    border-radius: 17px;

    h6 {
      font-weight: bold;
    }

    ul {
      list-style-type: none;
      padding-left: 16px;
    }
  }
}

@media (max-width: 991px) {
  .carousel {
    img {
      height: 300px;
      max-width: 100%;
      width: auto;
      display: block;
      margin: 0 auto;
      object-fit: cover;
    }
  }

  .productPrice {
    border-bottom: none;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;

    h5 {
      margin-right: 0;
    }

    h3 {
      span {
        font-size: 13px;
      }
    }

    .priceList {
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  .productDescription {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .popover {
    max-width: calc(100% - 10px) !important;
    min-width: 200px !important;
  }
}
