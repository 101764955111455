.card {
  :global(.card-body) {
    padding: 1.5rem;

    h3 {
      span {
        margin-left: 5px;
        font-size: 16px;
      }
    }
    @media (max-width: 991px) {
      p {
        font-size: 1.5rem !important;
      }
    }
  }
}
