.fullFilter {
  font-size: 15px;
  color: #9e9e9e;
  margin-bottom: 0;
  select {
    background: transparent;
    border: none;
    text-align: center;
    margin-left: 5px;
    outline: none;
  }
}

.quickFilter {
  h4 {
    color: var(--bs-primary);
    padding: 0 5px 1rem 5px;
    border-bottom: 2px solid var(--bs-secondary);
    width: fit-content;
    margin-bottom: 0.5rem;
  }

  button {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: var(--bs-primary);
    padding: 0;
    opacity: 1;
    transition: 0.1s ease;

    &:disabled {
      opacity: 0;
    }
  }

  ul {
    li {
      span {
        display: flex;
      }
      &:last-child {
        &::before {
          background-color: var(--bs-body-bg);
        }
      }
    }
  }
}

.helpPopover {
  max-width: 400px;
  :global(.popover-arrow) {
    display: block;
  }

  :global(.popover-body) {
    padding: 0.75rem;

    p {
      padding: 0;
      padding-right: 0.75rem;
      display: block;
      border-bottom: none;
    }

    img {
      padding-left: 0.75rem;
      width: 200px;
    }
  }
}

@media (max-width: 991px) {
  .fullFilter {
    font-size: 1rem;
    color: initial;
    h5 {
      width: 100%;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid var(--bs-secondary);
    }
    div {
      padding: 0 auto;
    }
  }

  .quickFilter {
    color: initial;

    h5 {
      width: 100%;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid var(--bs-secondary);
      margin-bottom: 0.5rem;
    }

    > ul {
      max-height: 30vh;
      overflow-y: auto;
    }

    ul {
      li {
        &:last-child {
          &::before {
            background-color: #fff;
            box-shadow: -1px 0 0 #fff;
          }
        }
      }
    }
  }

  .helpPopover {
    max-width: 50%;
    :global(.popover-body) {
      > div {
        flex-direction: column;
      }

      p {
        text-align: justify;
        padding-right: 0;
        margin-bottom: 1rem;
      }

      img {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}
