$color-text: #9e9e9e;
$color-line: #9e9e9e;
$color-bg: #fff;

$item-height: 1.5rem;
$item-padding: 0.3rem;

$font-size: 1rem;

.tree {
  svg {
    margin-right: 0.5rem;
    cursor: pointer;

    &[data-icon='plus'] {
      margin-left: 1rem;
    }
  }

  .grabbable {
    cursor: grab;
  }
}

.tree,
.tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tree ul {
  margin-left: 0.5rem;
  position: relative;
}
.tree ul ul {
  margin-left: 0.5rem;
}
.tree ul:before {
  content: '';
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid;
  border-color: $color-line;
}
.tree li {
  margin: 0;
  padding: $item-padding 1rem;
  line-height: $item-height;
  color: $color-text;
  position: relative;

  &:not(ul) {
    padding-left: 0;
    padding-right: 0;
  }

  ul {
    li {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  span {
    color: $color-text;

    button {
      color: $color-text;
      font-size: $font-size;
      text-align: left;

      &:global(.active) {
        color: #333;
        font-weight: bold;
        border-bottom: 2px solid var(--bs-secondary);
      }
    }

    &:hover {
      color: #333;

      & > svg {
        color: var(--bs-secondary);
      }
    }
  }
}

.tree ul li:before {
  content: '';
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: calc($item-height / 2) + $item-padding;
  left: 0;
  border-color: $color-line;
}
.tree ul li:last-child:before {
  background: $color-bg;
  height: auto;
  bottom: 0;
}

.valid-drag-hover {
  background-color: green;
  opacity: 0.3;
  cursor: grabbing;
}

.invalid-drag-hover {
  background-color: red;
  opacity: 0.3;
  cursor: not-allowed;
}

.grabbable:active {
  cursor: grabbing;
}
