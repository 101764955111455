.settings {
  :global(.card-header) {
    padding: 0;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 100;

    :global(.nav) {
      margin: 0;

      &:global(.nav-pills .nav-link) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      a {
        padding: 1rem;
      }
    }
  }
  :global(.col-6) {
    &:has(input[name='COMPANY_THEME_LOGO']) {
      order: 2;
    }
    &:has(input[name='COMPANY_THEME_FAVICON']) {
      order: 1;
    }
  }
}
