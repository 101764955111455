$border-radius: 0.5rem;

.popover {
  border-radius: $border-radius;

  & > :global(.popover-header) {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  & > :global(.popover-body) {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 1rem;
    color: #fff;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  background: var(--bs-danger);

  &.popoverInfo {
    background: var(--bs-secondary);
    color: black;
    & > :global(.popover-arrow:after) {
      border-left-color: var(--bs-secondary);
    }
  }

  & > :global(.popover-arrow:after) {
    border-right-color: var(--bs-danger);
  }
}

.popoverButton {
  padding: 0;
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
}

.popoverExpand {
  border: 0;
  padding: 0;

  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
}
