.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: var(--bs-gray-100);
  border: 3px dashed var(--bs-gray-500);
  padding: 2rem 0.5rem;

  p {
    color: var(--bs-gray-700);
    font-weight: bold;
    font-style: italic;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    color: var(--bs-primary);
    font-weight: bold;
    border-bottom: 3px solid var(--bs-secondary);
    width: fit-content;
    margin-bottom: 0;
  }
}

.resultsCard {
  h4 {
    font-weight: bold;
    border-bottom: 2px solid black;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-of-type {
      margin-bottom: 1rem;
      font-weight: bold;
    }

    p {
      max-width: 49%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.manualQuickOrder {
  :global(.close.btn-close.rbt-close) {
    span {
      display: none;
    }
  }
  .single {
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      max-width: calc(100% - 300px);
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > div {
      max-width: 300px;
      margin-left: auto;
    }
  }
}
