.inline-action {
  display: none;
  cursor: pointer;
  margin-left: 0.5em;
}

.inline-action:hover {
  color: grey;
}

.option:hover .inline-action {
  display: inline;
}

.variant:hover .inline-action {
  display: inline;
}

.title {
  p {
    color: #c9c9c9;
  }
}
