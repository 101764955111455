$sidebar-width: 20rem;
$sidebar-item-border: 0.5rem;
$main-width: calc(100% - $sidebar-width);
$color-line: #9e9e9e;
$item-height: 2rem;
$item-padding: 0.3rem;
$color-bg: #fff;
$nav-link-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  :global(.nav) {
    flex-direction: column;
  }

  :global(.nav-item) {
    padding-left: 0;
  }

  a:global(.nav-link) {
    padding-left: calc(var(--bs-gutter-x) + 1rem - $sidebar-item-border + 1rem);
    margin-left: calc(-1 * var(--bs-gutter-x) / 2);

    position: relative;
    border-left-style: solid;
    border-left-width: $sidebar-item-border;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    svg {
      margin-right: 0.2rem;
      font-size: 0.4rem;
      margin-bottom: 0.2rem;
    }

    color: #9e9e9e;
    border-left-color: transparent;

    /**
     * active and hover
     */
    &:global(.active) {
      color: #333;
      svg {
        color: var(--bs-secondary);
      }
    }

    &:global(:not(.active)):hover {
      color: #333;
      svg {
        color: var(--bs-secondary);
      }
    }
  }

  a:global(.nav-link):not(.subItem) {
    padding-left: calc(var(--bs-gutter-x) + 1rem - $sidebar-item-border);
    margin-left: calc(-1 * var(--bs-gutter-x) / 2);

    svg {
      width: 2rem;
      font-size: 1rem;
      margin-bottom: 0;
    }

    /**
     * rounded borders (doing a circle doubling the size and showing a quarter)
     */
    &:after,
    &:before {
      transition: $nav-link-transition;
      content: '';
      position: absolute;
      width: calc(2 * $sidebar-item-border);
      height: calc(2 * $sidebar-item-border);
      border-radius: 100%;
    }
    &:after {
      left: calc(-2 * $sidebar-item-border);
      top: calc(-1 * $sidebar-item-border);
    }
    &:before {
      left: calc(-2 * $sidebar-item-border);
      bottom: calc(-1 * $sidebar-item-border);
    }

    /**
     * active and hover
     */
    &:global(.active) {
      border-left-color: var(--bs-secondary);
      &:after,
      &:before {
        background-color: var(--bs-secondary);
      }
    }
  }

  div:global(.accordion-collapse) {
    div:global(.nav) {
      position: relative;
      &:before {
        content: '';
        height: 10px;
        display: block;
        width: 0;
        position: absolute;
        top: -2px;
        bottom: 0;
        left: calc(var(--bs-gutter-x) + 1rem - 0.2rem);
        border-left: 1px solid;
        border-color: $color-line;
      }
      a {
        margin-bottom: 0;
      }
      a:first-child:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-top: 1px solid;
        border-right: 1px solid;
        margin-top: -1px;
        position: absolute;
        top: 0;
        left: calc(var(--bs-gutter-x) + 1.1rem);
        border-color: $color-line;
      }
      a:before {
        content: '';
        display: block;
        width: 1px;
        height: 24px;
        border-top: 1px solid;
        border-right: 1px solid;
        margin-top: -1px;
        position: absolute;
        top: calc(0.5rem - var(--bs-gutter-x));
        left: calc(var(--bs-gutter-x) + 1.7rem);
        border-color: $color-line;
      }
    }
  }

  .sideBarHeader {
    margin-top: 4rem;
    margin-bottom: 2rem;
    a {
      text-decoration: none;
    }

    h1 {
      margin: 0 3rem;
      img {
        width: 100%;
      }
    }
  }

  .sideBarMain {
    margin-top: auto;
    margin-bottom: auto;

    a {
      margin-left: 2rem;
      svg {
        font-size: 1.5rem;
        border: 3px dashed #fff;
        border-radius: 100%;
        padding: 5px;
      }
      span {
        padding-left: 0.5rem;
      }

      &:hover {
        svg {
          border-color: var(--bs-secondary);
        }
      }
    }
  }

  .sideBarFooter {
    margin-top: auto;
    margin-bottom: 3rem;
  }
}
