@import '../../shared.scss';

.imageTitleColumn {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div {
    width: 50px;
    height: 50px;
    margin-right: 15px;

    img {
      border-radius: 5px;
      object-fit: cover;
      max-width: 50px;
      width: auto;
      display: block;
      margin: auto;
      height: 100%;
      mix-blend-mode: darken;
    }
  }

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
}

.categoriesButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;

  button {
    background-color: transparent;
    border: none;
    color: var(--bs-primary);
  }
}

.popover {
  padding: 1rem;
  max-width: 40%;
  width: 200px;
  border: none;
  z-index: 100;
  box-shadow: 0px -1px 33px #00000012;
  border-radius: 17px;

  div {
    &:first-child {
      &::before {
        border-bottom-color: #fff;
      }
    }
  }

  .categories {
    padding: 0;

    h6 {
      font-weight: bold;
    }

    p {
      margin-left: 1rem;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.productsTable {
  table {
    thead {
      tr {
        th {
          &:last-child {
            text-align: center;
          }
        }
      }
    }
  }
}

.ctaButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;

    svg {
      margin-right: 10px;
      font-size: 20px;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
