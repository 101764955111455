@import '../../shared';

$bg-color: #f2f2f2;

.filterByTitle {
  :global(.input-group) {
    margin-bottom: 1rem;
  }
}

.btn {
  text-align: center;
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);
  border-radius: 2.25rem;
  background: #fff;
  padding: 0.5rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  user-select: none;

  &.current {
    color: #fff !important;
    background: var(--bs-secondary);
    border-color: var(--bs-secondary);
  }
  &.active {
    color: var(--bs-secondary);
    background: #fff;
    border-color: var(--bs-secondary);
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  & > svg {
    cursor: pointer;
    font-size: 1.2rem;
  }

  & > div {
    margin-left: auto;
    margin-right: auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  flex: 1 1;
}

.containerTop {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  flex-direction: column;
  margin-bottom: 1rem;

  :global(.row) {
    align-items: flex-end;
  }
  :global([class*='col-']):last-child {
    text-align: right;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    color: #9e9e9e;
    font-size: 1.25rem;
    strong {
      color: var(--bs-secondary);
    }
  }
  h3,
  h4 {
    margin-bottom: 1rem;
  }
}
.containerMain {
  display: flex;
  width: 100%;
}

.sidebarFilter {
  position: relative;
  & > svg {
    position: absolute;
    right: 0;
    top: 0.25rem;
  }
}

.sidebar {
  display: flex;
  width: 30%;

  svg {
    cursor: pointer;
  }

  :global(.card) {
    width: 100%;
    background-color: $bg-color;
  }
  :global(.card-title) {
    text-align: center;
    color: var(--bs-secondary);
  }
  :global(.card-body) {
    padding: 1rem;
  }

  :global(.card-text) {
    small {
      color: #666;
    }
  }

  hr:last-child {
    display: none;
  }

  hr {
    margin: 1rem;
    opacity: 1;
    background: none;
    line-height: 1em;
    position: relative;
    color: black;
    text-align: center;
    height: 1.5em;
    content: attr(data-content);

    &:before {
      content: '';
      background: var(--bs-secondary);
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }

    &:after {
      content: attr(data-content);
      position: relative;
      padding: 0 0.5em;
      line-height: 1.5em;

      color: var(--bs-secondary);
      background-color: $bg-color;
    }
  }
}

.main {
  display: flex;
  flex: auto;
  width: 30%;
}

.linkContainer {
  margin: auto 0;
  :global(.col) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin-bottom: 0;
    text-align: center;
    background-color: $input-bg;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    margin-right: 10px;
    font-size: 1.5rem;
  }
}
