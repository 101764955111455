.register {
  :global(.card) {
    width: 50%;

    :global(.card-body) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  svg {
    position: absolute;
    bottom: 0;

    &:global(.woman) {
      left: 0;
      transform: translateX(-90%);
      width: 400px;
      height: fit-content;
    }

    &:global(.man) {
      bottom: 0;
      right: 0;
      transform: translateX(90%);
      height: -webkit-fill-available;
      max-height: 600px;
      width: auto;
    }
  }
}

@media (max-width: 991px) {
  .register {
    margin-bottom: 90px;
    :global(.card) {
      width: 100%;

      :global(.card-body) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    svg {
      position: absolute;
      bottom: 0;

      &:global(.woman) {
        left: initial;
        right: 0;
        bottom: -15%;
        transform: translateX(10px);
        width: 250px;
      }
    }
  }
}
