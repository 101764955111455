.fieldsCol {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 29rem);
  overflow-y: auto;
}

.fieldItem {
  text-align: center;
  border: 1px solid #333;
  border-radius: 2.25rem;
  background: #fff;
  padding: 0.3rem;
  margin-bottom: 0.75rem;
}

.fieldsColFrom {
  .fieldItem {
    color: var(--bs-primary);
    border-color: var(--bs-primary);

    opacity: 1;
    &.dragging {
      opacity: 0.5;
    }
  }
}

.fieldsColTo {
  .fieldItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    & > svg {
      cursor: pointer;
      font-size: 1.2rem;
    }

    & > div {
      margin-left: auto;
      margin-right: auto;
    }

    opacity: 0.3;
    &.matched {
      opacity: 1;
      color: #fff;
      border-color: var(--bs-secondary);
      background-color: var(--bs-secondary);
    }

    &.highlight {
      opacity: 1;
    }
  }
}

.fieldsColProduct {
  .fieldItem {
    color: var(--bs-dark);
    border-color: var(--bs-dark);
    opacity: 0.5;

    &.highlight {
      opacity: 1;
    }
  }
}

.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
}
