.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  :global(.card) {
    min-height: 400px;
    width: 75%;

    :global(.card-body) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.loginMessage {
  a {
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .cards {
    :global(.card) {
      height: inherit;
      width: 100%;
    }
  }
}
