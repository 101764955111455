.form {
  :global(.card-title) {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    div {
      display: block;
      margin-left: auto;
    }

    button {
      &:global(.btn-trasparent) {
        color: #fff;
      }
      svg {
        width: 14px;
      }
    }
  }
  :global(.col) {
    > div {
      display: flex;
      align-items: center;

      :global(.form-label) {
        margin-right: 1rem;
        margin-bottom: 0;
        white-space: nowrap;
      }

      input,
      select,
      textarea,
      p {
        background-color: transparent;
        border: none;
        padding: 0;
        color: #fff;
        font-size: 13px;
        box-shadow: none;
      }

      p {
        margin-bottom: 0;
        min-height: calc(1.5em + 1rem + 2px);
        display: flex;
        align-items: center;
        width: 100%;
      }

      select {
        background-image: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27><path fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/></svg>');
        color: inherit;
      }

      textarea {
        color: var(--bs-dark);
        resize: none;
      }

      :global(.input-group-text) {
        background-color: transparent;
        border: none;
      }
    }
  }
}

.summaryButton {
  border: none;
}

.purchaseOrderInfo {
  display: flex;
  align-items: center;
  height: 100%;

  div {
    border-right: 2px solid var(--bs-secondary);
    height: 100%;
    display: flex;
    align-items: center;

    &:last-child {
      border: none;

      button {
        padding-right: 0;
      }
    }
  }
}
