.summaryButton {
  border: none;
  display: flex;
  align-items: center;
}

.cart {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9e9e9e;
  font-weight: normal;
  font-size: initial;
  padding: 0 2rem !important;
  border-left: 3px solid var(--bs-secondary);
  color: #000;
  font-weight: bold;
  font-size: 20px;
}

.popover {
  border: none;
  box-shadow: 0px -1px 33px #00000012;
  border-radius: 17px;
  max-width: 400px;
  min-width: 300px;

  :global(.popover-arrow) {
    display: none;
  }

  :global(.popover-body) {
    font-weight: bold;
    p {
      padding: 1rem 1.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 0;
      display: flex;

      span {
        margin-left: auto;
        span {
          margin-left: 5px;
          font-size: 12px;
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
