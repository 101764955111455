.pagination {
  display: flex;
  align-items: center;

  :global(.page-link) {
    background: transparent;
    padding: 0 5px;
    border: none;
  }

  :global(.page-item.active) {
    :global(.page-link) {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 2px solid var(--bs-primary);
      color: var(--bs-primary);
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
}
