.status {
  svg {
    font-size: 0.4rem;
    margin-bottom: 0.2rem;
  }

  &.draft {
    svg {
      color: var(--bs-warning);
    }
  }
  &.new {
    svg {
      color: var(--bs-success);
    }
  }
}
