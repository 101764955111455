.customFields {
  :global(.card) {
    margin-bottom: 1rem;

    p {
      text-overflow: ellipsis;
      overflow-x: auto;
      text-wrap: nowrap;
    }

    :global(.card-header) {
      padding: 1rem 1.5rem;
      p {
        width: 70%;
      }

      button {
        padding: 0.3rem 0.55rem;

        &:first-of-type {
          margin-right: 5px;
        }
      }
    }
    :global(.card-body) {
      padding: 1.5rem;
      p {
        width: 100%;
      }
    }
  }
}

.ctaButton {
  padding: 0.75rem;
}
