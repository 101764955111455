$cell-pad: 1rem;

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;

  border-radius: 1rem;
  background: #000;
  box-shadow: 0 0 0.3rem 0.3rem #000;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;

  &.loading {
    visibility: visible;
    opacity: 0.05;
    transition: opacity 0.3s, visibility 0.3s;
  }
}
.container {
  position: relative;
}

.tableInteractionButtons {
  display: flex;
  justify-content: flex-end;

  > div {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .showingResults {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 15px;
      color: #9e9e9e;
      margin-bottom: 0;

      span {
        color: var(--bs-primary);
      }
    }
  }

  .orderingFilter {
    display: flex;
    align-items: center;
    p {
      font-size: 15px;
      color: #9e9e9e;
      margin-bottom: 0;
      white-space: nowrap;
      select {
        background: transparent;
        border: none;
        text-align: center;
        margin-left: 5px;
        outline: none;
      }
    }
  }
}

.table {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0 1rem;

  --bs-table-striped-bg: #fff;
  --bs-table-accent-bg: var(--bs-body-bg);

  &.selectable {
    th:first-child,
    td:first-child {
      position: sticky;
      left: 0px;
      width: 0.3rem;
    }
  }

  tbody {
    tr {
      background-color: #fff;
    }
  }

  & > tbody > tr > td:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  & > tbody > tr > td:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  & > :not(caption) > * > * {
    padding: $cell-pad 1.5rem;
    border-bottom-width: 0;
    box-shadow: none;
  }

  & > :not(:first-child) {
    border-top: none;
  }

  td {
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15rem;
  }
  :global(.btn-link) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.filterDropdown {
  :global(.dropdown-divider) {
    margin: 0;
  }
  :global(.dropdown-header) {
    padding: 1rem 1rem 0.5rem;
  }
  button[type='submit'] {
    margin: 0.5rem;
  }
  .filterButton {
    svg {
      path {
        fill: transparent;
        stroke: currentColor;
        stroke-width: 40px;
      }
    }
  }

  .choiceFilterable {
    max-height: 300px;
    overflow-y: auto;
  }
}

.optionButtons {
  padding-left: 1rem;
  border-left: 2px solid var(--bs-gray-100);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
