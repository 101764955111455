@import './index';

.container {
  z-index: 100;
  position: sticky;

  background: linear-gradient(to bottom, var(--bs-body-bg) 70%, transparent);
  padding-left: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  top: 0;

  h1 {
    margin-bottom: 0;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user {
    font-size: 2rem;
  }

  .dropdownMenu {
    p {
      margin-bottom: 0;
      padding: 0.75rem 1rem;
      white-space: nowrap;
      color: var(--bs-primary);
    }
  }

  .border {
    border-right: 2px solid var(--bs-secondary);
  }
}
