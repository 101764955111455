@import './index';

.container {
  z-index: 100;
  position: sticky;

  background: linear-gradient(to top, var(--bs-body-bg) 70%, transparent);
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  top: 0;

  text-align: right;
  button {
    margin-left: 1rem;
  }
}
