@import '../../shared';

.containerBrowse {
  @include box-parent;
  @include box-child-centered;

  .browseInput {
    @include box-parent;
  }

  .browseMessage {
    @include box-child-centered;
    align-items: center;
    text-align: center;
  }
}

.divider {
  border: 0;
  border-top: 1px solid var(--bs-primary);
  margin: 10px auto;
  width: 120px;
}

.containerFiles {
  @include box-parent;

  .filesAccepted {
    @include box-child;
  }
  .filesMessage {
    @include box-child-centered;

    align-items: center;
  }
}

.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
}
