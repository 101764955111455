@import './index';

.container {
  display: flex;
  min-height: 100vh;
  // background-color: red;
}

.sidebarContainer {
  z-index: 101;
  width: $sidebar-width;
  box-shadow: 0px 27px 33px #00000024;
  position: fixed;
  min-height: 100vh;
  background-color: white;
  height: 100%;
}

.mainContainer {
  margin-left: $sidebar-width;
  width: calc(100% - $sidebar-width);
  display: flex;
  flex-direction: column;
  height: 100vh;
  // background-color: red;
}

.main {
  z-index: 99;
  margin-bottom: auto;
  flex: auto;
  height: 100%;
  overflow-y: auto;
  // background-color: yellow;
}

.mainTop {
  top: 0;
  z-index: 100;
  position: sticky;
  // background-color: blue;
}

.mainBottom {
  bottom: 0;
  z-index: 100;
  position: sticky;
  //margin-top: auto;
  // background-color: blue;
}
