.store {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.banner {
  svg {
    filter: drop-shadow(2px 3px 3px rgb(0 0 0 / 0.4));
    font-size: 25px;
  }
}

.navbar {
  box-shadow: 0px -1px 33px #00000012;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .cart {
    border-left: 3px solid var(--bs-secondary);
    color: #000;
    font-weight: bold;
    font-size: 20px;
    display: flex;

    svg {
      color: var(--bs-secondary);
      font-size: 30px;
      margin-right: 10px;
    }
  }

  .menu {
    margin: auto 0;

    svg {
      margin-right: 0 !important;
    }

    :global(.dropdown-menu) {
      right: 2rem;
      p {
        margin: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
        border-bottom: 1px solid rgba(var(--bs-secondary), 0.35);
        text-align: center;
        white-space: nowrap;
      }
    }
  }

  .callToAction {
    &:global(.nav-link) {
      background-color: var(--bs-secondary);
      color: #fff;
      border-radius: 20px;
      border: 1px solid var(--bs-secondary);
      padding: 0.3rem 0.5rem;
      justify-content: space-between;
      font-weight: 600;
      margin: auto 5px auto 0;
      height: min-content;

      &:not(:has(svg)) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 0.5rem;
      }

      svg {
        color: #fff;
        margin-right: 10px;
        margin-left: 0;
      }

      &:hover,
      &:active,
      &:focus {
        color: var(--bs-secondary);
        background-color: transparent;

        svg {
          color: var(--bs-secondary);
        }
      }
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }

  :global(.nav-item) {
    &:last-of-type {
      :global(.nav-link) {
        padding-right: 0;
      }
    }
  }

  :global(.nav-link) {
    --bs-navbar-nav-link-padding-x: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9e9e9e;
    font-weight: normal;
    font-size: initial;
    padding-top: 0;
    padding-bottom: 0;

    svg {
      color: var(--bs-secondary);
      font-size: 30px;
      margin-right: 10px;
    }

    &.whatsapp {
      background-color: #25d366;
      border-radius: 100%;
      width: 60px;
      height: 60px;
      padding: 0;
      margin-left: 1.5rem;
      box-shadow: -1px 2px 5px rgba(0, 0, 0, 0.3);
      svg {
        color: #fff;
        margin-right: 0;
        font-size: 35px;
      }
    }
  }

  :global(.logo) {
    height: 70px;
  }

  :global(.navbar-toggler) {
    padding: 0;
    border: none;
    margin-left: 10px;
  }
}

.storeFooter {
  background-color: #fff;
  font-size: 0.9rem;

  .powered {
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    font-size: 1rem;
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      margin-right: 10px;
      svg {
        font-size: 27px;
        color: var(--bs-secondary);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .companyInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background-color: var(--bs-secondary);
      padding: 0.6rem;
      margin-right: 5px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1rem;
        height: 1rem;
      }

      svg {
        color: #fff;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}

.storeContent {
  margin-bottom: 2rem;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

@media (max-width: 991px) {
  .navbar {
    :global(.logo) {
      height: 40px;
    }

    :global(.navbar-nav) {
      align-items: flex-end;
    }

    .callToAction {
      &:global(.nav-link) {
        padding: 0 0.5rem !important;
        width: fit-content;
        margin: 0 0 0 auto;

        &:not(:has(svg)) {
          padding-top: 0rem;
          padding-bottom: 0rem;
          padding-right: 0.5rem;
        }
      }
    }

    .cart {
      border-left: none;

      button {
        padding: 0;

        strong {
          font-size: 16px;
        }

        svg {
          padding-left: 0 !important;
          padding-right: 0 !important;
          font-size: 20px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  .storeFooter {
    a {
      svg {
        font-size: 1rem;
        color: var(--bs-secondary);
      }
    }

    .social {
      margin-bottom: 0.5rem;
    }

    .powered {
      order: 3;
      justify-content: center;
    }

    .companyInfo {
      justify-content: center;
      order: 1;
      margin-bottom: 0.5rem;

      div {
        padding: 0.5rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    :global(.nav-link) {
      justify-content: flex-end;
      font-size: 16px;
      padding: 0.5rem 0 !important;

      &.cart {
        border-left: none;
        padding-left: 0;
        font-size: 16px;

        svg {
          font-size: 20px;
        }
      }

      svg {
        font-size: 20px;
      }
    }

    .menu {
      margin: 0 0 0 5px;

      :global(.dropdown-menu) {
        right: 0;
      }
    }

    .callToAction {
      &:global(.nav-link) {
        margin-left: 5px;
      }
    }

    :global(.navbar-toggler) {
      padding: 0;
      border: none;
      margin-left: 5px;
    }
  }
}

@media (max-width: 390px) {
  .navbar {
    :global(.logo) {
      height: 30px;
    }
  }
}
