.pills {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.75rem;
    border-radius: 16px;
    background-color: var(--bs-secondary);
    color: #fff;
    font-weight: bold;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 13px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      color: #fff;
      padding: 0;
      margin-right: 10px;
    }

    code {
      color: #fff;
    }
  }
}

.variantsSelectionPopover {
  padding: 1rem;
  width: 400px;
  ul {
    margin-top: 1rem;
    max-height: 350px;
    overflow-y: auto;
  }
}

.stepTitle {
  p {
    color: #c9c9c9;
  }
}

.stepDetails {
  :global(.stock) {
    height: 80px;
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      align-items: center;
      height: 100%;
      input {
        height: 70%;
        width: 70px;
        box-shadow: none;

        &:checked {
          background-color: var(--bs-secondary);
          border-color: var(--bs-secondary);

          &:focus {
            border-color: var(--bs-secondary);
            background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
          }
        }

        &:focus {
          border-color: rgba(black, 0.25);
          background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
        }
      }

      label {
        margin-left: 10px;
      }
    }
  }

  :global(.description) {
    display: flex;
    flex-direction: column;
    height: 276px;

    textarea {
      height: 100%;
    }
  }
}

.StepPhotos {
  form {
    border-right: 3px solid var(--bs-gray-100);
    padding-right: 1.5rem;
  }

  p {
    color: #c9c9c9;
  }

  h4 {
    color: var(--bs-primary);
  }

  ul {
    padding-left: 1.5rem;
    list-style-position: outside;

    li {
      margin-bottom: 1rem;

      div {
        display: flex;
        align-items: baseline;
        img {
          width: 100px;

          &:first-of-type {
            margin-right: 10px;
          }
        }
      }

      &::marker {
        color: var(--bs-secondary);
        font-size: 30px;
        line-height: 1;
      }
    }
  }
}
