@import "./../../index.scss";

.container {
  max-height: 100vh;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 100%;

  .leftColumn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20vh;
  }

  .description {
    display: none;
  }

  .rightColumn {
    .mobile {
      font-size: 2.75rem;
      font-weight: 300;
      text-align: center;
      margin-bottom: 3rem;
    }

    .desktop {
      display: none;
    }
  }

  .circles {
    display: none;
  }

  .woman {
    position: absolute;
    bottom: 85px;
    width: 140px;
    right: 70px;
  }

  .man {
    display: none;
  }

  .logo {
    width: calc(100% - var(--bs-gutter-x));
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(sm) {
    .leftColumn {
      margin-top: 0;
    }

    .description {
      display: block;
    }

    .rightColumn {
      position: relative;
      background-color: var(--bs-primary);
      height: 100vh;
      display: flex;

      .mobile {
        display: none;
      }

      .desktop {
        display: block;
        color: var(--bs-light);
        font-size: 4rem;
        margin-bottom: 3rem;


        span {
          font-size: 6rem;
        }

      }



      &> :global(.container) {
        margin-top: auto;
        margin-bottom: auto;
      }

      :global(.invalid-feedback) {
        color: var(--bs-light);
      }

      input {
        &::placeholder {
          color: var(--bs-dark);
        }
      }

      button[type='submit'] {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }

    .circles {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      max-height: 100vh;
      height: 100%;
      overflow-y: hidden;
      display: flex;
      align-items: center;

      img {
        margin-left: -35rem;
        margin-top: -17rem;
      }
    }

    .logo {
      max-width: 400px;
      margin-bottom: 54px;

    }

    .woman {
      width: initial;
      right: -1.5rem;
      bottom: 0;
      z-index: 1;
    }

    .man {
      display: block;
      position: absolute;
      left: -1.5rem;
      bottom: 0;
      z-index: 1;
    }
  }
}
